// Modules
import { kebabCase } from 'lodash'
import React from 'react'
import styled from '@emotion/styled'
import {
  graphql,
  Link,
  StaticQuery,
} from 'gatsby'

// Theme
import theme from 'scripts/theme'

// Styles
const TagListStyles = styled.ul`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  gap: ${theme.space.betweenRows} ${theme.space.betweenColumns};

  li a {
    display: block;
    padding: .5rem ${theme.space.all};
    background-color: ${theme.color.lightGrey};
    color: ${theme.color.white};
    text-align: center;
    border-radius: 5px;
  }
`

const TagList = () => (
  <StaticQuery
    query={
      graphql`
        query TagsQuery {
          allMarkdownRemark(limit: 1000) {
            group(field: frontmatter___tags) {
              fieldValue
              totalCount
            }
          }
        }
      `
    }
    render={data => {
      const { allMarkdownRemark: { group }} = data

      return (
        <TagListStyles>
          {
            group.map(tag => (
              <li key={tag.fieldValue}>
                <Link to={`/blog/tags/${kebabCase(tag.fieldValue)}/`}>
                  {tag.fieldValue} ({tag.totalCount})
                </Link>
              </li>
            ))
          }
        </TagListStyles>
      )
    }}
  />
)

export default TagList
