// Modules
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

// Components
import Container from 'components/global-components/Container'
import Layout from 'components/global-components/Layout'
import MetaData from 'components/global-components/MetaData'
import TagList from 'components/TagList'

// PropTypes
const propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        header: PropTypes.string.isRequired,
        metadata: PropTypes.objectOf(PropTypes.any).isRequired,
      }),
    }),
  }),
}

const TagIndex = ({ data }) => {
  const {
    markdownRemark: {
      frontmatter: {
        header,
        metadata,
      },
    },
  } = data

  return (
    <Layout hasNoHero>
      <MetaData metaData={metadata} />
      <section className='bottom-section-space'>
        <Container>
          <h1 style={{ textAlign: 'center' }}>{header}</h1>
          <TagList />
        </Container>
      </section>
    </Layout>
  )
}

TagIndex.propTypes = propTypes
export default TagIndex
export const pageQuery = graphql`
  query TagIndexTemplate {
    markdownRemark(frontmatter: {templateKey: {eq: "tag-index"}}) {
      frontmatter {
        metadata {
          title
          
        }
        header
      }
    }
  }
`
